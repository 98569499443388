@use "../../ui-kit/colors.scss" as c;
@import "../../ui-kit/mixins.scss";

.greyed-text {
  color: c.$deepest-blue-6;
}

.regular-text {
  @include fs-12;
}

.sm-text {
  @include fs-10;
}

.module-text {
  color: c.$new-leaf-green-5;
  border-bottom: 1px solid c.$new-leaf-green-5;
  cursor: pointer;
  transition: .3s ease-in-out;

  &:hover {
    color: c.$new-leaf-green-3;
    border-bottom-color: c.$new-leaf-green-3;;
  }
}
